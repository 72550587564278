<template>
    <div>
      <div class="settings--content">
        <settings-section
          :title="$t('SCHOOL_INBOX_CONFIGURATION.TITLE')"
          :sub-title="
            $t('SCHOOL_INBOX_CONFIGURATION.SUBTITLE')
          "
        >
          <div class="school-settings--content columns">
            <multiselect
                class="input"

                v-model="selectedSchoolAccount"
                :options="schoolAccounts"
                track-by="id"
                label="school_name"
    
                :placeholder="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')"
                selected-label
                :select-label="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_INBOX_CONFIGURATION.SCHOOL_ACCOUNT_SELECT.REMOVE')"

                @select="updateInboxSchoolAccount"
                />
          </div>
        </settings-section>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import alertMixin from 'shared/mixins/alertMixin';
  import inboxMixin from 'shared/mixins/inboxMixin';
  import SettingsSection from '../../../../../components/SettingsSection';

  export default {
    components: {
      SettingsSection,
    },
    mixins: [inboxMixin, alertMixin],
    props: {
      inbox: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        selectedSchoolAccount: null
      };
    },
   
    computed: {
        ...mapGetters({
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
        }),

        currentAccount() {
            return this.getAccount(this.accountId);
        },

        schoolAccounts() {
            console.log()
            return this.currentAccount?.school_accounts;
        }, 
    },

    mounted() {
      console.log(this.inbox);
      this.selectedSchoolAccount = this.inbox?.school_account;
    },

    methods: {
      async updateInboxSchoolAccount(selectedSchoolAccount) {
        try {
          const payload = {
            id: this.inbox.id,
            school_account_id: selectedSchoolAccount.id
          };

          await this.$store.dispatch('inboxes/updateInbox', payload);
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .school-settings--content {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: var(--space-small);
  
    .input {
      flex: 1;
      margin-right: var(--space-small);
      ::v-deep input {
        margin-bottom: 0;
      }
    }
  }
  </style>
  