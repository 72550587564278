<template>
    <form class="contact--form" @submit.prevent="handleSubmitEnrollmentForm">
        <div class="row" v-if="!isNewEnrollment && !enrollment">
            <div class="medium-9 columns">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.LABEL') }}
                </label>
                <multiselect v-model="enrollment" track-by='id' label="student_name"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.REMOVE')" :max-height="160"
                    :options="enrollments" :allow-empty="true" :option-height="104" @select="chooseEnrollment" />
            </div>
            <div class="medium-3 columns add-button-area">
                <div class="add-button">
                    <woot-button size="small" variant="link" icon="add" @click="newEnrollmentButtonToggle">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.LABEL') }}
                    </woot-button>
                </div>
            </div>
        </div>

        <div v-if="enrollment || isNewEnrollment"> 
            <div class="medium-12 columns enrollment-title">
                <label class="page-sub-title" style="font-size: medium; font-weight: bold;">
                    {{ (isNewEnrollment ? $t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.TITLE') :
                        `${$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.TITLE')} - ${enrollment.student_name}`) }}
                </label>
            </div>

            <div class="row">
                <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.LABEL') }}
                </label>

                <multiselect v-model="selectedSchool" track-by="id" label="school_name"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.REMOVE')" :max-height="160"
                    :options="schoolAccounts" :allow-empty="true" :option-height="104" @select="chooseSchool" />

            </div>

            <label v-if="hasAnotherSchoolDocument">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.LABEL') }}
                <input v-model.trim="schoolDocument" type="text" v-mask="'##.###.###/####-##'" 
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.PLACEHOLDER')" />
            </label>

            <label :class="{ error: $v.number.$error }" >
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.LABEL') }}
                    <input v-model.trim="number" type="text" 
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.PLACEHOLDER')" @input="$v.number.$touch" />

                    <div v-if="isNewEnrollment" class="medium-12 field-button">
                        <woot-button variant="clear" @click="fetchEgdEnrollment" size="small" :isLoading="egdLoading">
                            {{ this.$t('SCHOOL_CONTACT_FORM.FORM.FETCH_ENROLLMENT_EGD.BUTTON_LABEL') }}
                        </woot-button>
                    </div>        
            </label>

            <label :class="{ error: $v.schoolClass.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.LABEL') }}
                <input v-model.trim="schoolClass" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.PLACEHOLDER')" @input="$v.schoolClass.$touch" />
                <span v-if="$v.schoolClass.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CLASS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolYear.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.LABEL') }}
                <input v-model.trim="schoolYear" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.PLACEHOLDER')" @input="$v.schoolYear.$touch"
                    v-mask="'####'" />
                <span v-if="$v.schoolYear.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_YEAR.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.studentName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.LABEL') }}
                <input v-model.trim="studentName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.PLACEHOLDER')" @input="$v.studentName.$touch" />
                <span v-if="$v.studentName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.LABEL') }}
                <input v-model.trim="responsibleName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.PLACEHOLDER')"
                    @input="$v.responsibleName.$touch" />
                <span v-if="$v.responsibleName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.LABEL') }}
                <input v-model.trim="responsibleZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.responsibleZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.responsibleZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.responsibleState.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.LABEL') }}
                </label>
                <multiselect v-model="responsibleState" track-by='ID' label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104" />
                <span v-if="$v.responsibleState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.responsibleCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.LABEL') }}
                <input v-model.trim="responsibleCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.PLACEHOLDER')"
                    @input="$v.responsibleCity.$touch" />
                <span v-if="$v.responsibleCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.LABEL') }}
                <input v-model.trim="responsibleDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.PLACEHOLDER')"
                    @input="$v.responsibleDistrict.$touch" />
                <span v-if="$v.responsibleDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleStreet.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.LABEL') }}
                <input v-model.trim="responsibleStreet" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.PLACEHOLDER')"
                    @input="$v.responsibleStreet.$touch" />
                <span v-if="$v.responsibleStreet.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_STREET.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleBuildingNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.LABEL') }}
                <input v-model.trim="responsibleBuildingNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.PLACEHOLDER')"
                    @input="$v.responsibleBuildingNumber.$touch" />
                <span v-if="$v.responsibleBuildingNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_BUILDING_NUMBER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.responsibleFirstDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.LABEL') }}
                <input v-model.trim="responsibleFirstDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.responsibleFirstDocument.$touch" v-mask="'###.###.###-##'" />
                <span v-if="$v.responsibleFirstDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RESPONSIBLE_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.tuition.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION.LABEL') }}
                <input v-model.trim="tuition" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TUITION.PLACEHOLDER')" @input="$v.tuition.$touch" 
                    v-mask="moneyMask"/> 
                <span v-if="$v.tuition.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.tuitionDueDate.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.LABEL') }}
                <input v-model.trim="tuitionDueDate" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.PLACEHOLDER')"
                    @input="$v.tuitionDueDate.$touch" />
                <span v-if="$v.tuitionDueDate.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.TUITION_DUE_DATE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.installmentNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.LABEL') }}
                <input v-model.trim="installmentNumber" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.PLACEHOLDER')"
                    @input="$v.installmentNumber.$touch" min="1"/>
                <span v-if="$v.installmentNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_NUMBER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.discount.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.DISCOUNT.LABEL') }}
                <input v-model.trim="discount" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.DISCOUNT.PLACEHOLDER')" @input="$v.discount.$touch"
                    v-mask="moneyMask">
                <span v-if="$v.discount.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.DISCOUNT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.punctualityDiscountRule.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.LABEL') }}
                <input v-model.trim="punctualityDiscountRule" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.PLACEHOLDER')"
                    @input="$v.punctualityDiscountRule.$touch" />
                <span v-if="$v.punctualityDiscountRule.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_RULE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.costCenter.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.LABEL') }}
                <input v-model.trim="costCenter" type="text" disabled class="disabled"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.PLACEHOLDER')" @input="$v.costCenter.$touch" />
                <span v-if="$v.costCenter.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.COST_CENTER.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="modal-footer">
                <div class="medium-12 columns">
                    <woot-submit-button :loading="inProgress" :button-text="$t('SCHOOL_CONTACT_FORM.FORM.SUBMIT')" />
                    <button class="button clear" @click.prevent="onCancel">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CANCEL') }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { isKeduFieldValid } from 'shared/helpers/KeduValidators';
import maskMixin from 'shared/mixins/inputMaskMixin';
import SchoolEnrollmentApi from 'dashboard/api/kedu/schoolEnrollments.js';

export default {
    props: {
        contact: {
            type: Object,
            default: () => ({}),
        },
        inProgress: {
            type: Boolean,
            default: false,
        },
        selectedSchoolLabels: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            number: '',
            schoolClass: '',
            schoolYear: '',
            studentName: '',
            responsibleName: '',
            responsibleZipCode: '',
            responsibleState: '',
            responsibleCity: '',
            responsibleDistrict: '',
            responsibleStreet: '',
            responsibleBuildingNumber: '',
            responsibleFirstDocument: '',
            tuition: '',
            tuitionDueDate: '',
            installmentNumber: '',
            discount: '',
            punctualityDiscountRule: '',
            costCenter: '',
            schoolDocument: '',

            enrollment: null,
            selectedSchool: null,
            hasAnotherSchoolDocument: null,
            isNewEnrollment: false,
            egdLoading: false,
        };
    },
    mixins: [alertMixin, maskMixin],
    validations() {
        return {
            number: {},
            schoolClass: { required, keduValidator: this.checkDataValidation('schoolClass') },
            schoolYear: { required, keduValidator: this.checkDataValidation('schoolYear') },
            studentName: { required, minLength: minLength(4), keduValidator: this.checkDataValidation('fullname') },
            responsibleName: { required, keduValidator: this.checkDataValidation('fullname') },
            responsibleZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
            responsibleState: { required },
            responsibleCity: { required, keduValidator: this.checkDataValidation('city') },
            responsibleDistrict: { required },
            responsibleStreet: { required, keduValidator: this.checkDataValidation('street') },
            responsibleBuildingNumber: { required },
            responsibleFirstDocument: { required, keduValidator: this.checkDataValidation('firstDocument') },
            tuition: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
            tuitionDueDate: { required },
            installmentNumber: { required, keduValidator: this.isInstallmentNotValid },
            discount: { required, keduValidator: this.isDiscountNotValid },
            punctualityDiscountRule: { required },
            costCenter: { required },
        };
    },
    computed: {
        ...mapGetters({
            enrollments: 'schoolEnrollments/getSchoolEnrollments',
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
        }),
        contactId() {
            return this.contact.id;
        },
        currentAccount() {
            return this.getAccount(this.accountId);
        },
        schoolAnotherOption() {
            return {
                id: 'another_option',
                school_name: this.$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ACCOUNT_SELECT.ANOTHER_OPTION_LABEL'),
                school_document: "",
                another_option: true,
            }
        },
        schoolAccounts() {
            let schoolOptions = [this.schoolAnotherOption];
            if (this.currentAccount?.school_accounts && this.currentAccount?.school_accounts.length) {
                schoolOptions = [...this.currentAccount?.school_accounts, ...schoolOptions]
            }
            return schoolOptions;
        },
        checkDataValidation() {
            return (dataParams) => (value) => isKeduFieldValid(value, dataParams);
        },
        isDiscountNotValid() {
            if (parseFloat(this.discount) > parseFloat(this.tuition)) {
                return true;
            }
            return false;
        },
        isInstallmentNotValid() {
            if (this.installmentNumber > this.installmentLimit) {
                return true;
            }
            return false;
        },
        installmentLimit() {
            const countToJanuary = 12- new Date().getMonth();
            return countToJanuary < 6 ? countToJanuary : 6
        },
    },
    mounted() {
        this.fetchLabels();
    },
    watch: {
        contactId() {
            this.onCancel();
        },
        discount(val) {
            const parsedTuition = this.moneyMaskToDecimal(this.tuition);
            const parsedVal = this.moneyMaskToDecimal(val);

            if ((parsedVal > parsedTuition) ||
                (this.tuition == '')) {
                this.discount = this.tuition;
            }
        },
        schoolYear(val) {
            const year = new Date().getFullYear();
            if (parseInt(val) < parseInt(year)) {
                this.schoolYear = year;
            }
        },
        installmentNumber(val) {
            if (parseInt(val) > this.installmentLimit) {
                this.installmentNumber = this.installmentLimit;
            }
        }
    },
    methods: {
        onCancel() {
            this.enrollment = null;
            this.isNewEnrollment = false;

            this.number = '';
            this.schoolClass = '';
            this.schoolYear = '';
            this.studentName = '';
            this.responsibleName = '';
            this.responsibleZipCode = '';
            this.responsibleState = '';
            this.responsibleCity = '';
            this.responsibleDistrict = '';
            this.responsibleStreet = '';
            this.responsibleBuildingNumber = '';
            this.responsibleFirstDocument = '';
            this.tuition = '';
            this.tuitionDueDate = '';
            this.installmentNumber = '';
            this.discount = '';
            this.punctualityDiscountRule = '';
            this.costCenter = '';
            this.schoolDocument = '';

            this.schoolDocument = '';
        },
        newEnrollmentButtonToggle() {
            this.isNewEnrollment = true;
            this.costCenter = 'KEDU ONE MATRICULA 2024'
        },
        enrollmentPayload() {
            return ({
                contactId: this.contact.id,
                number: this.number,
                school_class: this.schoolClass,
                school_year: this.schoolYear,
                student_name: this.studentName,
                responsible_name: this.responsibleName,
                responsible_zip_code: this.responsibleZipCode,
                responsible_state: this.responsibleState.ID,
                responsible_city: this.responsibleCity,
                responsible_district: this.responsibleDistrict,
                responsible_street: this.responsibleStreet,
                responsible_building_number: this.responsibleBuildingNumber,
                responsible_first_document: this.responsibleFirstDocument,
                tuition: this.moneyMaskToDecimal(this.tuition),
                tuition_due_date: this.tuitionDueDate,
                installment_number: this.installmentNumber,
                discount: this.moneyMaskToDecimal(this.discount),
                punctuality_discount_rule: this.punctualityDiscountRule,
                cost_center: this.costCenter,
                enrollment_school_document: this.schoolDocument
            });
        },
        async updateEnrollment() {
            const payload = this.enrollmentPayload();
            try {
                await this.$store.dispatch('schoolEnrollments/update', { id: this.enrollment.id, ...payload });
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                if(error.cause.number){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.UNIQUENESS_ERROR_MESSAGE'));
                } else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_ENROLLMENT.ERROR_MESSAGE'));
                }
            }
        },
        async createEnrollment() {
            const payload = this.enrollmentPayload();
            try {
                await this.$store.dispatch('schoolEnrollments/create', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                if(error.cause.attributes.find(item => item === 'number')){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENT_NUMBER.UNIQUENESS_ERROR_MESSAGE'));
                }  else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.NEW_ENROLLMENT.ERROR_MESSAGE'));
                }
            }
        },
        mapEgdFields(fields) {
            this.studentName = fields?.AlunoNome || '';
            this.responsibleName = fields?.ResponsavelNome || '';
            this.responsibleZipCode = fields?.ResponsavelCEP || '';
            this.responsibleState = fields?.ResponsavelEstado ? this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => fields?.ResponsavelEstado === option.ID) : '';
            this.responsibleCity = fields?.ResponsavelCidade || '';
            this.responsibleDistrict = fields?.ResponsavelBairro || '';
            this.responsibleStreet = fields?.ResponsavelRua || '';
            this.responsibleBuildingNumber = fields?.ResponsavelNumero || '';
            this.responsibleFirstDocument = fields?.ResponsavelDocumento || '';
        },
        async fetchEgdEnrollment() {
            this.egdLoading = true;
            try {
                const response = await SchoolEnrollmentApi.fetchEgdEnrollment(this.contact.id,this.number, this.schoolDocument);
                this.mapEgdFields(JSON.parse(response.data));
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.FETCH_ENROLLMENT_EGD.ERROR_MESSAGE'));
                
            } finally {
                this.egdLoading = false;
            }
            
        },
        handleSubmitEnrollmentForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if (this.isNewEnrollment) {
                this.createEnrollment();
            } else {
                this.updateEnrollment();
            }
        },

        chooseEnrollment(selectedEnrollment) {
            this.isNewEnrollment = false;

            this.number = selectedEnrollment.number || '';
            this.schoolClass = selectedEnrollment.school_class || '';
            this.schoolYear = selectedEnrollment.school_year || '';
            this.studentName = selectedEnrollment.student_name || '';

            this.responsibleName = selectedEnrollment.responsible_name || '';
            this.responsibleZipCode = selectedEnrollment.responsible_zip_code || '';
            this.responsibleState = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => selectedEnrollment.responsible_state === option.ID) || '';

            this.responsibleCity = selectedEnrollment.responsible_city || '';
            this.responsibleDistrict = selectedEnrollment.responsible_district || '';
            this.responsibleStreet = selectedEnrollment.responsible_street || '';
            this.responsibleBuildingNumber = selectedEnrollment.responsible_building_number || '';
            this.responsibleFirstDocument = selectedEnrollment.responsible_first_document || '';

            this.tuition = selectedEnrollment.tuition && parseFloat(selectedEnrollment.tuition).toFixed(2) || '';
            this.tuitionDueDate = selectedEnrollment.tuition_due_date || '';
            this.installmentNumber = selectedEnrollment.installment_number || '';

            this.discount = selectedEnrollment.discount && parseFloat(selectedEnrollment.discount).toFixed(2) || '';
            this.punctualityDiscountRule = selectedEnrollment.punctuality_discount_rule || '';
            this.costCenter = selectedEnrollment.costCenter || 'KEDU ONE MATRICULA 2024';
            this.schoolDocument = selectedEnrollment.enrollment_school_document;

            this.selectedSchool = this.currentAccount?.school_accounts?.find(school => school.school_document === this.schoolDocument);
            if (!this.selectedSchool){
                this.hasAnotherSchoolDocument = true;
                this.selectedSchool = this.schoolAnotherOption;
            } else {
                this.hasAnotherSchoolDocument = false;
            }
        },

        chooseSchool(selectedSchool) {
            this.schoolDocument = selectedSchool.school_document;
            if (selectedSchool?.another_option) {
                this.hasAnotherSchoolDocument = true;
            } else {
                this.hasAnotherSchoolDocument = false;
            }
        },

        async fetchLabels() {
            if (!this.contact.id) {
                return;
            }
            this.$store.dispatch('contactLabels/get', this.contact.id);
        },
        validateMoneyGreaterThanZero(moneyValue) {
            if (this.moneyMaskToDecimal(moneyValue) <= 0) {
                return false 
            } 
            return true;
        }
    }
}

</script>
<styles scoped lang="scss">
.contact--form {
    padding: var(--space-normal) var(--space-large) var(--space-large);
    min-height: 200px;

    .columns {
        padding: 0 var(--space-smaller);
    }
}

.field-button {
    margin-top: -15px;
    display: flex;
    justify-content: end;
}

.add-button-area {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-bottom: 5px;
}

.add-button {
    height: 25px;
}

.enrollment-title {
    margin-bottom: 5px;
}
</styles>