<template>
    <form class="contact--form" @submit.prevent="handleSubmitEnrollmentForm">
        <div class="row" v-if="!enrollment">
            <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_TYPE') }}
            </label>
            <multiselect 
                v-model="contractType"
                :select-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')"
                :default-value="contractType"
                :allow-empty="false" :options="contractTypes" @select="chooseContractType" />

            <label>
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.LABEL') }}
            </label>
            <multiselect v-model="enrollment" track-by='id' label="student_name"
                :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.PLACEHOLDER')" selected-label
                :select-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.ENROLLMENTS.REMOVE')" :max-height="160" :options="enrollments"
                :allow-empty="true" :option-height="104" @select="chooseEnrollment" />
        </div>

        <div v-if="enrollment
            && contractType==='Digital'">
            <div class="medium-12 columns enrollment-title">
                <label class="page-sub-title" style="font-size: medium; font-weight: bold;">
                    {{ `${$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT.TITLE')} - ${enrollment.student_name}` }}
                </label>
            </div>

            <label :class="{ error: $v.contractorName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.LABEL') }}
                <input v-model.trim="contractorName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.PLACEHOLDER')"
                    @input="$v.contractorName.$touch" />
                <span v-if="$v.contractorName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorAddress.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.LABEL') }}
                <input v-model="contractorAddress" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.PLACEHOLDER')"
                    @input="$v.contractorAddress.$touch" />
                <span v-if="$v.contractorAddress.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ADDRESS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.LABEL') }}
                <input v-model="contractorDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.PLACEHOLDER')"
                    @input="$v.contractorDistrict.$touch" />
                <span v-if="$v.contractorDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.contractorState.$error }">
                <label >
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.LABEL') }}
                </label>
                <multiselect v-model="contractorState" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.contractorState.$touch" />
                <span v-if="$v.contractorState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.contractorCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.LABEL') }}
                <input v-model="contractorCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.PLACEHOLDER')"
                    @input="$v.contractorCity.$touch" />
                <span v-if="$v.contractorCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.LABEL') }}
                <input v-model="contractorZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.contractorZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.contractorZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorPhoneNumber.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.LABEL') }}
                <input v-model="contractorPhoneNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.PLACEHOLDER')"
                    @input="$v.contractorPhoneNumber.$touch" v-mask="'(##) #########'" />
                <span v-if="$v.contractorPhoneNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_PHONE_NUMBER.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorEmail.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.LABEL') }}
                <input v-model="contractorEmail" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.PLACEHOLDER')"
                    @input="$v.contractorEmail.$touch" />
                <span v-if="$v.contractorEmail.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_EMAIL.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorFirstDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.LABEL') }}
                <input v-model="contractorFirstDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.PLACEHOLDER')"
                    @input="$v.contractorFirstDocument.$touch" v-mask="'###.###.###-##'" />

                <span v-if="$v.contractorFirstDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_FIRST_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorSecondDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.LABEL') }}
                <input v-model="contractorSecondDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.PLACEHOLDER')"
                    @input="$v.contractorSecondDocument.$touch" />
                <span v-if="$v.contractorSecondDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_SECOND_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorBirthDate.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.LABEL') }}
                <input v-model.trim="contractorBirthDate" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.PLACEHOLDER')"
                    @input="$v.contractorBirthDate.$touch" :max="maxBirthDate" />

                <span v-if="$v.contractorBirthDate.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_BIRTH_DATE.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.contractorOccupation.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.LABEL') }}
                <input v-model="contractorOccupation" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.PLACEHOLDER')"
                    @input="$v.contractorOccupation.$touch" />

                <span v-if="$v.contractorOccupation.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_OCCUPATION.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.contractorMaritalStatus.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.LABEL') }}
                </label>
                <multiselect v-model="contractorMaritalStatus"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.REMOVE')" :max-height="160"
                    :options="this.$t('SCHOOL_CONTACT_FORM.FORM.MARITAL_STATUS.STATUS')" :allow-empty="true"
                    :option-height="104" @input="$v.contractorMaritalStatus.$touch" />

                <span v-if="$v.contractorMaritalStatus.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACTOR_MARITAL_STATUS.ERROR_MESSAGE') }}
                </span>
            </div>

            <div class="row" :class="{ error: $v.relationshipWithStudent.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.LABEL') }}
                </label>
                <multiselect v-model="relationshipWithStudent" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.relationshipWithStudent.$touch" />

                <span v-if="$v.relationshipWithStudent.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.ERROR_MESSAGE') }}
                </span>
            </div>

            <div class="row" :class="{ error: $v.studentParentsMaritalStatus.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.LABEL') }}
                </label>
                <multiselect v-model="studentParentsMaritalStatus" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentParentsMaritalStatus.$touch" />
                
                <span v-if="$v.studentParentsMaritalStatus.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="studentParentsMaritalStatus.ID === 'OTHER'" :class="{ error: $v.studentParentsMaritalStatusOther.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.LABEL') }}
                <input v-model="studentParentsMaritalStatusOther" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.PLACEHOLDER')"
                    @input="$v.studentParentsMaritalStatusOther.$touch" />

                <span v-if="$v.studentParentsMaritalStatusOther.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OTHER.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" v-if="studentParentsMaritalStatus.ID === 'DIVORCED'" :class="{ error: $v.studentCustodyArrangement.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.LABEL') }}
                </label>
                <multiselect v-model="studentCustodyArrangement" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentCustodyArrangement.$touch" />

                <span v-if="$v.studentCustodyArrangement.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.studentName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.LABEL') }}
                <input v-model="studentName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.PLACEHOLDER')"
                    @input="$v.studentName.$touch" />
                <span v-if="$v.studentName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_STUDENT_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.studentDateOfBirth.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.LABEL') }}
                <input v-model.trim="studentDateOfBirth" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.PLACEHOLDER')"
                    @input="$v.studentDateOfBirth.$touch" />
                <span v-if="$v.studentDateOfBirth.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_DATE_OF_BIRTH.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.studentSchoolingType.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.LABEL') }}
                </label>
                <multiselect v-model="studentSchoolingType"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS')" :allow-empty="true"
                    :option-height="104" @input="$v.studentSchoolingType.$touch" />

                <span v-if="$v.studentSchoolingType.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.studentSchoolClass.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.LABEL') }}
                <input v-model="studentSchoolClass" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.PLACEHOLDER')"
                    @input="$v.studentSchoolClass.$touch" />

                <span v-if="$v.studentSchoolClass.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOL_CLASS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolName.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.LABEL') }}
                <input v-model.trim="schoolName" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.PLACEHOLDER')" @input="$v.schoolName.$touch" />
                <span v-if="$v.schoolName.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_NAME.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolDocument.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.LABEL') }}
                <input v-model.trim="schoolDocument" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.PLACEHOLDER')"
                    @input="$v.schoolDocument.$touch" v-mask="'##.###.###/####-##'" />
                <span v-if="$v.schoolDocument.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DOCUMENT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolAddress.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.LABEL') }}
                <input v-model.trim="schoolAddress" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.PLACEHOLDER')"
                    @input="$v.schoolAddress.$touch" />
                <span v-if="$v.schoolAddress.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ADDRESS.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolDistrict.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.LABEL') }}
                <input v-model.trim="schoolDistrict" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.PLACEHOLDER')"
                    @input="$v.schoolDistrict.$touch" />
                <span v-if="$v.schoolDistrict.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_DISTRICT.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.schoolCity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.LABEL') }}
                <input v-model.trim="schoolCity" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.PLACEHOLDER')" @input="$v.schoolCity.$touch" />
                <span v-if="$v.schoolCity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_CITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.schoolState.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.LABEL') }}
                </label>
                <multiselect v-model="schoolState" track-by="ID" label="NAME"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.REMOVE')" :max-height="160"
                    :options="$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS')" :allow-empty="true" :option-height="104"
                    @input="$v.schoolState.$touch" />

                <span v-if="$v.schoolState.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_STATE.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.schoolZipCode.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.LABEL') }}
                <input v-model.trim="schoolZipCode" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.PLACEHOLDER')"
                    @input="$v.schoolZipCode.$touch" v-mask="'#####-###'" />
                <span v-if="$v.schoolZipCode.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_ZIP_CODE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.schoolPhoneNumber.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.LABEL') }}
                </label>
                <input v-model.trim="schoolPhoneNumber" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.PLACEHOLDER')"
                    @input="$v.schoolPhoneNumber.$touch" v-mask="'(##) #########'" />
                <span v-if="$v.schoolPhoneNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_PHONE_NUMBER.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.schoolEmail.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.LABEL') }}
                <input v-model.trim="schoolEmail" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.PLACEHOLDER')" @input="$v.schoolEmail.$touch" />
                <span v-if="$v.schoolEmail.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.SCHOOL_EMAIL.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.annuity.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.LABEL') }}
                <input v-model.trim="annuity" type="text" :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.PLACEHOLDER')"
                    @input="$v.annuity.$touch" v-mask="moneyMask" />
                <span v-if="$v.annuity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.ANNUITY.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.installmentsNumber.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_NUMBER.LABEL') }}
                </label>
                <multiselect v-model="installmentsNumber"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_NUMBER.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_NUMBER.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_NUMBER.REMOVE')" :max-height="160"
                    :options="installments" :allow-empty="true" :option-height="104" @input="$v.installmentsNumber.$touch"/>
                <span v-if="$v.installmentsNumber.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENTS_NUMBER.ERROR_MESSAGE') }}
                </span>
            </div>

            <label v-if="installmentsNumber === 11" :class="{ error: $v.installmentIn11.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_11.LABEL') }}
                <input v-model.trim="installmentIn11" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_11.PLACEHOLDER')"
                    @input="$v.installmentIn11.$touch" v-mask="moneyMask" />
                <span v-if="$v.installmentIn11.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_11.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 11" :class="{ error: $v.startDateInstallmentIn11.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_11.LABEL') }}
                <input v-model.trim="startDateInstallmentIn11" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_11.PLACEHOLDER')"
                    @input="$v.startDateInstallmentIn11.$touch" />
                <span v-if="$v.startDateInstallmentIn11.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_11.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 11" :class="{ error: $v.lastDayInstallmentIn11.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_11.LABEL') }}
                <input v-model.trim="lastDayInstallmentIn11" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_11.PLACEHOLDER')"
                    @input="$v.lastDayInstallmentIn11.$touch" />
                <span v-if="$v.lastDayInstallmentIn11.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_11.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 12" :class="{ error: $v.installmentIn12.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_12.LABEL') }}
                <input v-model.trim="installmentIn12" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_12.PLACEHOLDER')"
                    @input="$v.installmentIn12.$touch" v-mask="moneyMask" />
                <span v-if="$v.installmentIn12.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_12.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 12" :class="{ error: $v.startDateInstallmentIn12.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_12.LABEL') }}
                <input v-model.trim="startDateInstallmentIn12" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_12.PLACEHOLDER')"
                    @input="$v.startDateInstallmentIn12.$touch" />
                <span v-if="$v.startDateInstallmentIn12.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_12.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 12" :class="{ error: $v.lastDayInstallmentIn12.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_12.LABEL') }}
                <input v-model.trim="lastDayInstallmentIn12" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_12.PLACEHOLDER')"
                    @input="$v.lastDayInstallmentIn12.$touch" />
                <span v-if="$v.lastDayInstallmentIn12.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_12.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 13" :class="{ error: $v.installmentIn13.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_13.LABEL') }}
                <input v-model.trim="installmentIn13" type="text"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_13.PLACEHOLDER')"
                    @input="$v.installmentIn13.$touch" v-mask="moneyMask" />
                <span v-if="$v.installmentIn13.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_IN_13.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 13" :class="{ error: $v.startDateInstallmentIn13.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_13.LABEL') }}
                <input v-model.trim="startDateInstallmentIn13" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_13.PLACEHOLDER')"
                    @input="$v.startDateInstallmentIn13.$touch" />
                <span v-if="$v.startDateInstallmentIn13.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.START_DATE_INSTALLMENT_IN_13.ERROR_MESSAGE') }}
                </span>
            </label>

            <label v-if="installmentsNumber === 13" :class="{ error: $v.lastDayInstallmentIn13.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_13.LABEL') }}
                <input v-model.trim="lastDayInstallmentIn13" type="date"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_13.PLACEHOLDER')"
                    @input="$v.lastDayInstallmentIn13.$touch" />
                <span v-if="$v.lastDayInstallmentIn13.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.LAST_DAY_INSTALLMENT_IN_13.ERROR_MESSAGE') }}
                </span>
            </label>

            <label :class="{ error: $v.punctualityDiscountPercentage.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.LABEL') }}
                <input v-model.trim="punctualityDiscountPercentage" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.PLACEHOLDER')"
                    @input="$v.punctualityDiscountPercentage.$touch" step="0.01" min="0" max="100" />
                <span v-if="$v.punctualityDiscountPercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.ERROR_MESSAGE') }}
                </span>
            </label>

            <div class="row" :class="{ error: $v.installmentsMaturity.$error }">
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.LABEL') }}
                </label>
                <multiselect v-model="installmentsMaturity"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.PLACEHOLDER')" selected-label
                    :select-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.SELECT_PLACEHOLDER')"
                    :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.REMOVE')" :max-height="160"
                    :options="days" :allow-empty="true" :option-height="104" @input="$v.installmentsMaturity.$touch" />
                <span v-if="$v.installmentsMaturity.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.INSTALLMENT_MATURITY.ERROR_MESSAGE') }}
                </span>
            </div>

            <label :class="{ error: $v.withdrawalFeePercentage.$error }">
                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.LABEL') }}
                <input v-model.trim="withdrawalFeePercentage" type="number"
                    :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.PLACEHOLDER')"
                    @input="$v.withdrawalFeePercentage.$touch" step="0.01" min="0" />
                <span v-if="$v.withdrawalFeePercentage.$error" class="message">
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITHDRAWAL_FEE_PERCENTAGE.ERROR_MESSAGE') }}
                </span>
            </label>

            <section>
                <label>
                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.LABEL') }}
                </label>
                <div class="medium-12 columns filters-wrap">
                    <div class="filters">
                        <div>
                            <div v-if="witnesses.length === 0">
                                <label class="filter-none">
                                    {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NO_HAVE_WITNESS') }}
                                </label>
                            </div>
                            <div v-else>
                                <div class="filter" v-for="(field, index) in witnesses" :key="index">
                                    <label class="filter-title">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.HEADER_TITLE') }} {{ index + 1 }}:
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].name.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NAME_LABEL') }}
                                        <input v-model.trim="field.name" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.NAME_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].name.$touch" />
                                        <span v-if="$v.witnesses.$each[index].name.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_NAME_MESSAGE') }}
                                        </span>
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].phone.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.PHONE_LABEL') }}
                                        <input v-model.trim="field.phone" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.PHONE_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].phone.$touch" v-mask="'(##) #########'" />
                                        <span v-if="$v.witnesses.$each[index].phone.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_PHONE_MESSAGE') }}
                                        </span>
                                    </label>
                                    <label :class="{ error: $v.witnesses.$each[index].email.$error }">
                                        {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.EMAIL_LABEL') }}
                                        <input v-model.trim="field.email" type="text"
                                            :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.WITNESS.EMAIL_PLACEHOLDER')"
                                            @input="$v.witnesses.$each[index].email.$touch" />
                                        <span v-if="$v.witnesses.$each[index].email.$error" class="message">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ERROR_EMAIL_MESSAGE') }}
                                        </span>
                                    </label>
                                    <div class="filter-delete">
                                      <woot-button
                                        icon="delete"
                                        color-scheme="alert"
                                        variant="smooth"
                                        size="small"
                                        @click.prevent="removeWitness(index)">
                                            {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.REMOVE_BUTTON') }}
                                      </woot-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-add">
                        <woot-button
                            icon="add"
                            color-scheme="success"
                            variant="smooth"
                            size="small"
                            @click.prevent="addWitness()">
                                {{ $t('SCHOOL_CONTACT_FORM.FORM.WITNESS.ADD_BUTTON') }}
                        </woot-button>
                    </div>
                </div>
            </section>
            <div class="modal-footer">
                <div class="medium-12 columns">
                    <woot-submit-button :loading="inProgress" :button-text="$t('SCHOOL_CONTACT_FORM.FORM.SUBMIT')" />
                    <button class="button clear" @click.prevent="onCancel">
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CANCEL') }}
                    </button>
                </div>
            </div>
        </div>
        <div>
            <section v-if="enrollment && contractType === 'Manual'">
                <section>
                    <label >
                        {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.LABEL') }}
                    </label>
                    <div class="medium-12 row file-section-wrap">
                        <label v-if="!contractFiles || contractFiles.length === 0" class="medium-12 file-section-empty-label">
                                {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.EMPTY_LABEL') }}
                        </label>
                        <div v-for="(contractFile, index) in contractFiles" class="medium-12 row file-section-item" >
                            <div v-if="contractFile && contractFile.data_url" class="medium-12 flex items-center justify-center">
                                <attachment-box 
                                    :url="contractFile.data_url"
                                />
                            </div>
                            <div class="medium-12 items-center flex justify-center">
                                <woot-button
                                    v-if="contractFile && contractFile.data_url"
                                    icon="delete"
                                    color-scheme="alert"
                                    variant="smooth"
                                    size="small"
                                    @click.prevent="removeFile(contractFile.id)"
                                >
                                    {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.REMOVE_BUTTON_LABEL') }}
                                </woot-button>
                            </div>
                        </div>
                        <input type="file"
                            ref="fileInput" 
                            accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"
                            @input="handleAttachFile"
                            style="display: none;"
                        />
                        <woot-button
                            v-if = "!contractFiles || contractFiles.length < 5"
                            class = "file-section-upload-button"
                            icon="upload"
                            color-scheme="primary"
                            variant="smooth"
                            size="small"
                            @click.prevent="openFileInput()"
                        >
                            {{ $t('SCHOOL_CONTACT_FORM.FORM.CONTRACT_FILE.ADD_BUTTON_LABEL') }}
                        </woot-button>
                    </div>
                </section>
                <div class="modal-footer">
                
            </div>
            </section>
        </div>
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import maskMixin from 'shared/mixins/inputMaskMixin';
import { isKeduFieldValid } from 'shared/helpers/KeduValidators';
import AttachmentBox from 'dashboard/components/widgets/conversation/bubble/File.vue';

export default {
    props: {
        contact: {
            type: Object,
            default: () => ({}),
        },
        inProgress: {
            type: Boolean,
            default: false,
        },
        selectedSchoolLabels: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        AttachmentBox
    },
    data() {
        return {
            enrollment: null,
            contractFiles: [],
            installmentsNumber: null,

            contractorName: '',
            contractorAddress: '',
            contractorDistrict: '',
            contractorState: '',
            contractorCity: '',
            contractorZipCode: '',
            contractorPhoneNumber: '',
            contractorEmail: '',
            contractorFirstDocument: '',
            contractorSecondDocument: '',
            contractorBirthDate: '',
            contractorOccupation: '',
            contractorMaritalStatus: '',

            relationshipWithStudent: '',
            studentParentsMaritalStatus: '',
            studentParentsMaritalStatusOther: '',
            studentCustodyArrangement: '',

            studentName: '',
            studentDateOfBirth: '',
            studentSchoolingType: '',
            studentSchoolClass: '',

            schoolCity: '',
            schoolEmail: '',
            schoolDocument: '',
            schoolName: '',
            schoolDistrict: '',
            schoolPhoneNumber: '',
            schoolState: '',
            schoolAddress: '',
            schoolZipCode: '',

            annuity: '',

            installmentIn11: '',
            installmentIn12: '',
            installmentIn13: '',

            startDateInstallmentIn11: '',
            startDateInstallmentIn12: '',
            startDateInstallmentIn13: '',

            lastDayInstallmentIn11: '',
            lastDayInstallmentIn12: '',
            lastDayInstallmentIn13: '',

            punctualityDiscountPercentage: '',
            withdrawalFeePercentage: '',
            installmentsMaturity: '',

            witnesses: [],

            maxBirthDate: this.getMaxBirthDate(),
            contractType: 'Digital',
        };
    },
    mixins: [alertMixin, maskMixin],
    validations() {
           let validations = {
                contractorName: { required, keduValidator: this.checkDataValidation('fullname') },
                contractorAddress: { required },
                contractorDistrict: { required },
                contractorState: { required },
                contractorCity: { required, keduValidator: this.checkDataValidation('city') },
                contractorZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
                contractorPhoneNumber: { required, minLength: minLength(8), },
                contractorEmail: { required, email, },
                contractorFirstDocument: { required, keduValidator: this.checkDataValidation('firstDocument') },
                contractorSecondDocument: { required, minLength: minLength(4) },
                contractorBirthDate: { required },
                contractorOccupation: { required },
                contractorMaritalStatus: { required },

                relationshipWithStudent: { required },
                studentParentsMaritalStatus: { required },

                studentName: { required, keduValidator: this.checkDataValidation('fullname') },
                studentDateOfBirth: { required },
                studentSchoolingType: { required },
                studentSchoolClass: { required },

                schoolName: { required, minLength: minLength(4), },
                schoolDocument: { required },
                schoolZipCode: { required, keduValidator: this.checkDataValidation('zipCode') },
                schoolState: { required },
                schoolCity: { required, keduValidator: this.checkDataValidation('city') },
                schoolDistrict: { required },
                schoolAddress: { required },
                schoolPhoneNumber: { required },
                schoolEmail: { required, email, },

                annuity: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },

                installmentsNumber: { required },

                installmentIn11: {},
                startDateInstallmentIn11: {},
                lastDayInstallmentIn11: {},

                installmentIn12: {},
                startDateInstallmentIn12: {},
                lastDayInstallmentIn12: {},

                installmentIn13: {},
                startDateInstallmentIn13: {},
                lastDayInstallmentIn13: {},

                punctualityDiscountPercentage: { required },
                withdrawalFeePercentage: { required },
                installmentsMaturity: { required },
                studentParentsMaritalStatusOther: {},
                studentCustodyArrangement: {},

                witnesses: {
                    $each: {
                        name: { required, keduValidator: this.checkDataValidation('fullname') },
                        phone: { minLength: minLength(8) },
                        email: { required, email },
                    },
                },
        };
        
        if (this.studentParentsMaritalStatus?.ID === "OTHER") {
            validations = { ...validations, studentParentsMaritalStatusOther: { required } }
        } else if (this.studentParentsMaritalStatus?.ID === "DIVORCED") {
            validations = { ...validations, studentCustodyArrangement: { required } }
        }

        if (this.installmentsNumber === 11){
            validations = {
                ...validations,
                installmentIn11: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                startDateInstallmentIn11: { required },
                lastDayInstallmentIn11: { required },
            }
        }
        if (this.installmentsNumber === 12){
            validations = {
                ...validations,
                installmentIn12: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                startDateInstallmentIn12: { required },
                lastDayInstallmentIn12: { required },
            }
        }
        if (this.installmentsNumber === 13){
            validations = {
                ...validations,
                installmentIn13: { required, moneyGreaterThanZero: this.validateMoneyGreaterThanZero },
                startDateInstallmentIn13: { required },
                lastDayInstallmentIn13: { required },
            }
        }

        return validations
    },
    computed: {
        ...mapGetters({
            enrollments: 'schoolEnrollments/getSchoolEnrollments',
            currentEnrollment:'schoolEnrollments/getEnrollment',
            getAccount: 'accounts/getAccount',
            accountId: 'getCurrentAccountId',
        }),
        contractTypes() {
            return ['Digital', 'Manual'];
        },
        currentAccount() {
            return this.getAccount(this.accountId);
        },
        checkDataValidation() {
            return (dataParams) => (value) => isKeduFieldValid(value, dataParams);
        },
        days() {
            return [5,10,15,20];
        },
        installments() {
            return [11,12,13];
        }
    },
    watch: {
        punctualityDiscountPercentage(val) {
            if (val > 100) {
                this.punctualityDiscountPercentage = 100;
            }
        },
    },
    mounted() {
        this.fetchLabels();
    },
    methods: {
        onCancel() {
            this.enrollment = null;
        },
        getStateById(id){
            return this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => id === option.ID);
        },
        getMaxBirthDate() {
            const currenteDate = new Date();
            const maxBirthDate = new Date(
                currenteDate.getFullYear() - 18,
                currenteDate.getMonth(),
                currenteDate.getDate()
            );
            return maxBirthDate.toISOString().split('T')[0];
        },
        enrollmentPayload() {
            return ({
                contactId: this.contact.id,

                contractor_name: this.contractorName,
                contractor_address: this.contractorAddress,
                contractor_district: this.contractorDistrict,
                contractor_state: this.contractorState.ID,
                contractor_city: this.contractorCity,
                contractor_zip_code: this.contractorZipCode,
                contractor_phone_number: this.contractorPhoneNumber,
                contractor_email: this.contractorEmail,
                contractor_first_document: this.contractorFirstDocument,
                contractor_second_document: this.contractorSecondDocument,
                contractor_birth_date: this.contractorBirthDate,
                contractor_occupation: this.contractorOccupation,
                contractor_marital_status: this.contractorMaritalStatus,

                relationship_with_student: this.relationshipWithStudent?.NAME,
                student_parents_marital_status: this.studentParentsMaritalStatus?.ID === "OTHER" ? this.studentParentsMaritalStatusOther : this.studentParentsMaritalStatus?.NAME,
                student_custody_arrangement: this.studentParentsMaritalStatus?.ID === "DIVORCED" && this.studentCustodyArrangement?.NAME,

                contract_student_name: this.studentName,
                student_date_of_birth: this.studentDateOfBirth,
                student_school_class: this.studentSchoolClass,
                student_schooling_type: this.studentSchoolingType,

                school_city: this.schoolCity,
                school_email: this.schoolEmail,
                school_document: this.schoolDocument,
                school_name: this.schoolName,
                school_district: this.schoolDistrict,
                school_phone_number: this.schoolPhoneNumber,
                school_state: this.schoolState?.ID,
                school_address: this.schoolAddress,
                school_zip_code: this.schoolZipCode,

                annuity: this.moneyMaskToDecimal(this.annuity),
                installment_in_11: (this.installmentsNumber === 11 && this.moneyMaskToDecimal(this.installmentIn11)) || null,
                installment_in_12: (this.installmentsNumber === 12 && this.moneyMaskToDecimal(this.installmentIn12))|| null,
                installment_in_13: (this.installmentsNumber === 13 && this.moneyMaskToDecimal(this.installmentIn13)) || null,

                start_date_installment_in_11: this.installmentsNumber === 11 && this.startDateInstallmentIn11 || null,
                start_date_installment_in_12: this.installmentsNumber === 12 && this.startDateInstallmentIn12 || null,
                start_date_installment_in_13: this.installmentsNumber === 13 && this.startDateInstallmentIn13 || null,

                last_day_installment_in_11: this.installmentsNumber === 11 && this.lastDayInstallmentIn11 || null,
                last_day_installment_in_12: this.installmentsNumber === 12 && this.lastDayInstallmentIn12 || null,
                last_day_installment_in_13: this.installmentsNumber === 13 && this.lastDayInstallmentIn13 || null,

                punctuality_discount_percentage: this.punctualityDiscountPercentage,
                withdrawal_fee_percentage: this.withdrawalFeePercentage,
                installments_maturity: this.installmentsMaturity,
                witnesses: JSON.stringify(this.witnesses),
            });
        },
        hasDataFinalizationLabel() {
            return this.hasIncludedLabel("09_finalização_de_dados") || this.hasIncludedLabel("10_assinatura_de_contrato") || this.hasIncludedLabel("11_matrícula") || this.hasIncludedLabel("12_contrato_recusado") || this.hasIncludedLabel("13_matrícula_manual") || this.hasIncludedLabel("14_contrato_parcial");
        },
        hasIncludedLabel(label) {
            return this.selectedSchoolLabels.includes(label);
        },
        async fetchLabels() {
            if (!this.contact.id) {
                return;
            }
            this.$store.dispatch('contactLabels/get', this.contact.id);
        },
        async handleSubmitEnrollmentForm() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const payload = this.enrollmentPayload();
            try {
                await this.$store.dispatch('schoolEnrollments/update', { id: this.enrollment.id, ...payload });
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_CONTRACT.SUCCESS_MESSAGE'));
                this.onCancel();
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.EDIT_CONTRACT.ERROR_MESSAGE'));
            }
        },
        chooseContractType(type) {
            this.contractType = type;
        },
        chooseEnrollment(selectedEnrollment) {
            const { school_contract, enrollment_school_document } = selectedEnrollment;
            const school_account = this.currentAccount?.school_accounts?.find(school => school.school_document === enrollment_school_document);
       
            this.contractFiles = school_contract?.contract_files || [];

            this.contractorName = school_contract?.contractor_name || selectedEnrollment?.responsible_name || '';
            this.contractorAddress = school_contract?.contractor_address || 
                (selectedEnrollment?.responsible_street + ", " + selectedEnrollment?.responsible_building_number) || '';

            this.contractorDistrict = school_contract?.contractor_district || selectedEnrollment?.responsible_district || '';
            this.contractorState = this.getStateById(school_contract?.contractor_state || selectedEnrollment?.responsible_state) || '';
            this.contractorCity = school_contract?.contractor_city || selectedEnrollment?.responsible_city || '';
            this.contractorZipCode = school_contract?.contractor_zip_code || selectedEnrollment?.responsible_zip_code|| '';
            this.contractorPhoneNumber = school_contract?.contractor_phone_number || '';
            this.contractorEmail = school_contract?.contractor_email || '';
            this.contractorFirstDocument = school_contract?.contractor_first_document || selectedEnrollment?.responsible_first_document || '';
            this.contractorSecondDocument = school_contract?.contractor_second_document || '';
            this.contractorBirthDate = school_contract?.contractor_birth_date || '';
            this.contractorOccupation = school_contract?.contractor_occupation || '';
            this.contractorMaritalStatus = school_contract?.contractor_marital_status || '';

            this.studentSchoolClass = school_contract?.student_school_class || selectedEnrollment?.school_class || '';
            this.studentSchoolingType = school_contract?.student_schooling_type || '';
            this.studentDateOfBirth = school_contract?.student_date_of_birth || '';
            this.studentName = school_contract?.contract_student_name || selectedEnrollment?.student_name || '';

            this.schoolCity = school_contract?.school_city || school_account?.school_city || '';
            this.schoolEmail = school_contract?.school_email || school_account?.school_email || '';
            this.schoolDocument = school_contract?.school_document || school_account?.school_document || enrollment_school_document || '';
            this.schoolName = school_contract?.school_name || school_account?.school_name || '';
            this.schoolDistrict = school_contract?.school_district || school_account?.school_district || '';
            this.schoolPhoneNumber = school_contract?.school_phone_number || school_account?.school_phone_number || '';
            this.schoolAddress = school_contract?.school_address || school_account?.school_address || '';
            this.schoolZipCode = school_contract?.school_zip_code || school_account?.school_zip_code || '';
            this.schoolState = this.getStateById(school_contract?.school_state) || this.getStateById(school_account?.school_state) || '';

            this.annuity = school_contract?.annuity && parseFloat(school_contract.annuity).toFixed(2);
            this.punctualityDiscountPercentage = school_contract?.punctuality_discount_percentage || '';
            this.installmentsMaturity = school_contract?.installments_maturity || '';
            this.withdrawalFeePercentage = school_contract?.withdrawal_fee_percentage || '';

            this.installmentsNumber = school_contract?.installment_in_11 ? 11 : school_contract?.installment_in_12 ? 12 : 13;

            this.installmentIn11 = school_contract?.installment_in_11 && parseFloat(school_contract.installment_in_11).toFixed(2);
            this.startDateInstallmentIn11 = school_contract?.start_date_installment_in_11 || '';
            this.lastDayInstallmentIn11 = school_contract?.last_day_installment_in_11 || '';

            this.installmentIn12 = school_contract?.installment_in_12 && parseFloat(school_contract.installment_in_12).toFixed(2);
            this.startDateInstallmentIn12 = school_contract?.start_date_installment_in_12 || '';
            this.lastDayInstallmentIn12 = school_contract?.last_day_installment_in_12 || '';

            this.installmentIn13 = school_contract?.installment_in_13 && parseFloat(school_contract.installment_in_13).toFixed(2);;
            this.startDateInstallmentIn13 = school_contract?.start_date_installment_in_13 || '';
            this.lastDayInstallmentIn13 = school_contract?.last_day_installment_in_13 || '';

            this.studentCustodyArrangement = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_CUSTODY_ARRANGEMENT.OPTIONS').find(
                option => option.NAME === school_contract?.student_custody_arrangement
            ) || '';

            this.relationshipWithStudent = this.$t('SCHOOL_CONTACT_FORM.FORM.RELATIONSHIP_WITH_STUDENT.OPTIONS')
                .find(option => option.NAME === school_contract?.relationship_with_student) || '';

            const maritalOption = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')
                .find(option => option.NAME === school_contract?.student_parents_marital_status) || '';

            if (maritalOption) {
                this.studentParentsMaritalStatus = maritalOption;
                this.studentParentsMaritalStatusOther = '';
            }
            else if (school_contract?.student_parents_marital_status) {
                this.studentParentsMaritalStatus = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_PARENTS_MARITAL_STATUS.OPTIONS')
                    .find(option => option.ID === "OTHER");
                this.studentParentsMaritalStatusOther = school_contract.student_parents_marital_status;
            } else {
                this.studentParentsMaritalStatus = '';
                this.studentParentsMaritalStatusOther = '';
            }
            this.witnesses = JSON.parse(school_contract?.witnesses) || [];
        },
        addWitness() {
          this.witnesses.push({
            name: '',
            phone: '',
            email: '',
          });
        },
        removeWitness(index) {
          this.witnesses.splice(index, 1);
        },
        async handleAttachFile(event) {
            const [file] = event.target.files;

            const payload = {
                id: this.enrollment.id, 
                contractFile: file,
                contactId: this.contact.id, 
            }

            try {
                await this.$store.dispatch('schoolEnrollments/addContractFile', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.SUCCESS_MESSAGE'));
                this.contractFiles = this.currentEnrollment(this.enrollment.id).school_contract.contract_files;
            } catch (error) {
                if(error.cause.contract_file){
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.INVALID_FILE_ERROR_MESSAGE'));
                } else if (error.cause.contract_files) {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.FILE_LIMIT_ERROR_MESSAGE'));
                } else {
                    this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.ADD_CONTRACT_FILE.ERROR_MESSAGE'));
                }
            }
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        async removeFile(contractFileId) {
            const payload = {
                id: this.enrollment.id, 
                contactId: this.contact.id,
                contract_file_id: contractFileId, 
            }
            try {
                await this.$store.dispatch('schoolEnrollments/removeContractFile', payload);
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.REMOVE_CONTRACT_FILE.SUCCESS_MESSAGE'));
                this.contractFiles = this.contractFiles.filter(contractFile => contractFile.id !== contractFileId);
            } catch (error) {
                this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.REMOVE_CONTRACT_FILE.ERROR_MESSAGE'));
            }
        },
        validateMoneyGreaterThanZero(moneyValue) {
            if (this.moneyMaskToDecimal(moneyValue) <= 0) {
                return false 
            } 
            return true;
        }
    }
}

</script>
<styles scoped lang="scss">

.contact--form {
    padding: var(--space-normal) var(--space-large) var(--space-large);
    min-height: 200px;

    .columns {
        padding: 0 var(--space-smaller);
    }
}

.add-button-area {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-bottom: 5px;
}

.add-button {
    height: 25px;
}

.enrollment-title {
    margin-bottom: 5px;
}

.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-border);
  margin-bottom: var(--space-normal);
  background: var(--white);
  @apply dark:bg-slate-900 dark:border-slate-600
}

.filter-add {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-normal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  background: var(--w-25);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-top: var(--space-smaller);
  @apply dark:bg-slate-800 dark:border-slate-600
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-none {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-normal);
  font-weight: bold;
}

.filter-title {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.file-section-empty-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.file-section-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);
    padding: var(--space-small) ;
    background: var(--white);
    gap: var(--space-small);
    @apply dark:bg-slate-900 dark:border-slate-600
}

.file-section-item {
    background: var(--w-25);
    padding: var(--space-small);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-medium);
    @apply dark:bg-slate-800 dark:border-slate-600
}

.file-section-upload-button {
    margin-top: var(--space-smaller);
}

</styles>