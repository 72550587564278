<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('EXPORT_CONTACTS.TITLE')">
        <p>
          {{ $t('EXPORT_CONTACTS.DESC') }}
        </p>
      </woot-modal-header>
      <div class="row modal-content">
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-button
              :disabled="uiFlags.isCreating"
              :loading="uiFlags.isCreating"
              @click="downloadReportsCSV"
              id="download-btn"
            >
              {{ $t('EXPORT_CONTACTS.DOWNLOAD_CSV_LABEL') }}
            </woot-button>
            <woot-button
              :disabled="uiFlags.isCreating"
              :loading="uiFlags.isCreating"
              @click="downloadReportsXLSX"
            >
              {{ $t('EXPORT_CONTACTS.DOWNLOAD_XLSX_LABEL') }}
            </woot-button>
            <button class="button clear" @click.prevent="onClose">
              {{ $t('EXPORT_CONTACTS.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import configMixin from 'shared/mixins/configMixin';

export default {
  components: {
    Modal,
  },
  mixins: [alertMixin, configMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    query: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: true,
      file: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.EXPORT_MODAL_OPEN);
  },
  methods: {
    downloadReportsCSV() {
      if(this.isKedu) {
        this.downloadEnrollmentReportsCSV()
      } else {
        this.downloadContactReportsCSV()
      }
    },

    downloadReportsXLSX() {
      if(this.isKedu) {
        this.downloadEnrollmentReportsXLSX()
      } else {
        this.downloadContactReportsXLSX()
      }
    },

    downloadContactReportsCSV() {
      const fileName = 'contacts-report.csv';
      const params = { fileName, queryFilter: this.query, label: this.label };
      this.$store.dispatch('downloadContactsReportsCSV', params);
    },

    downloadContactReportsXLSX() {
      const fileName = 'contacts-report.xlsx';
      const params = { fileName, queryFilter: this.query, label: this.label };
      this.$store.dispatch('downloadContactsReportsXLSX', params);
    },

    downloadEnrollmentReportsCSV() {
      const fileName = 'school-enrollments-report.csv';
      const params = { fileName, queryFilter: this.query, label: this.label };
      this.$store.dispatch('downloadEnrollmentsReportsCSV', params);
    },

    downloadEnrollmentReportsXLSX() {
      const fileName = 'school-enrollments-report.xlsx';
      const params = { fileName, queryFilter: this.query, label: this.label };
      this.$store.dispatch('downloadEnrollmentsReportsXLSX', params);
    },
  },
};
</script>
